import './App.css';
import mercedes from './img/mercedes.png';
import phone from './img/phone.svg';
import email from './img/email.svg';
import ContactSide from './components/ContactSide';
import ContactDetail from './components/ContactDetail';

function App() {
	return (
		<div className="text-white min-w-full min-h-screen justify-center items-center flex">
			<div className="grid lg:grid-cols-2 lg:grid-rows-1 grid-rows-2 grid-cols-1 gap-10 flex-row">
				<div className="flex flex-col justify-center items-center lg:mx-5 lg:ml-5 mx-2">
					<img src={mercedes} width={350} alt="Mercedes-Benz" className="mt-6" />

					<h1 className="text-center text-5xl font-medium font-serif pt-5">PRESTIGE CARS</h1>
					<h3 className="text-center text-2xl font-medium font-serif mb-5">of Chipping Norton</h3>
					<div className="flex lg:flex-row flex-col text-sm items-center">
						<ContactDetail link="tel:07377461846" icon={phone}>
							07377 461846
						</ContactDetail>
						<ContactDetail link="mailto:chippingnortoncabs@gmail.com" icon={email}>
							chippingnortoncabs@gmail.com
						</ContactDetail>
					</div>

					<div className="lg:my-4 my-6 separator"></div>
					<p className="bio">
						Prestige Cars of Chipping Norton is the best choice for mini-cab and chauffeur services
						in the Cotswolds. We have over 20 years of experience in the transport industry from
						local grocery trips to airports and seaports. We cater for (HNWIs) airport shuttles,
						weddings, and other large events. Our drivers have extensive knowledge and training,
						enabling a smooth and comfortable journey.
					</p>
					<div className="lg:my-5 my-4"></div>
					<p className="font-serif text-3xl text-center">
						<em>
							“You’ve tried the rest,
							<br />
							now try the best.”
						</em>
					</p>
				</div>

				<ContactSide />
			</div>
		</div>
	);
}

export default App;
