export default function ContactDetail({ link, icon, children }) {
	return (
		<div className="flex mx-2 hover:cursor-pointer text-lg">
			<img src={icon} width="20" className="invert mr-1" alt="Contact icon" />
			<a href={link} className="link">
				{children}
			</a>
		</div>
	);
}
