export default function TextField({ placeholder, type, className, value, onChange }) {
	return (
		<input
			className={'text-field py-1.5 px-3 rounded-md transition-all ' + className}
			placeholder={placeholder}
			type={type}
			value={value}
			onChange={onChange}
		/>
	);
}
