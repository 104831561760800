export default function Button({ dark = false, text, onClick, className }) {
	return (
		<button
			className={
				`py-1.5 px-5 ${
					dark ? 'bg-black hover:bg-gray-900 text-white' : 'bg-white hover:bg-gray-300 text-black'
				} transition-all rounded-md mx-1 ` + className
			}
			onClick={onClick}
		>
			{text}
		</button>
	);
}
