import Button from './Button';
import TextField from './TextField';
import TextArea from './TextArea';
import { useState } from 'react';

export default function ContactSide() {
	const [name, setName] = useState();
	const [email, setEmail] = useState();
	const [phone, setPhone] = useState();
	const [message, setMessage] = useState();
	const [status, setStatus] = useState({ message: '', color: 'text-gray-300' });

	async function sendMessage() {
		if (!name || !email || !phone || !message) {
			setStatus({ message: 'All fields are required.', color: 'text-red-300' });
			return;
		}

		let data = {
			message: 'Message failed to send. Please try emailing or calling us.',
			color: 'text-red-300',
		};
		setStatus({ message: 'Sending...', color: 'text-gray-300' });
		try {
			let res = await fetch('https://prestigecarschippingnorton.com/.netlify/functions/contact', {
				method: 'POST',
				body: JSON.stringify({ name, email, phone, message }),
			});
			data = await res.json();
		} catch (error) {
			console.error(error);
		}
		setStatus(data);
		return data;
	}

	return (
		<div className="right-side lg:p-8 p-4 lg:mx-5 mx-2 lg:mr-5 rounded-3xl lg:mb-0 mb-5">
			{/* <p className="text-4xl font-semibold text-white">Get started</p> */}
			<Button text="Call us" onClick={() => window.open('tel:07377461846', '_self')} />
			<Button
				text="Email us"
				onClick={() => window.open('mailto:chippingnortoncabs@gmail.com', '_self')}
			/>{' '}
			<form
				className="mt-3"
				onSubmit={(e) => {
					e.preventDefault();
					console.log({ name, email, phone, message });
					sendMessage();
				}}
			>
				<div className="flex">
					<TextField
						placeholder="Name"
						type="text"
						className="my-1 w-full"
						onChange={(e) => setName(e.target.value)}
					/>
					<TextField
						placeholder="Phone number"
						type="tel"
						className="my-1 w-full ml-2"
						onChange={(e) => setPhone(e.target.value)}
					/>
				</div>
				<TextField
					placeholder="E-mail"
					type="email"
					className="my-1 w-full"
					onChange={(e) => setEmail(e.target.value)}
				/>
				<TextArea
					placeholder="Let us know what you need"
					className="my-1 w-full h-52"
					onChange={(e) => setMessage(e.target.value)}
				/>
				<Button text="Send" className="my-1" />{' '}
				<span className={`ml-2 ${status.color}`}>{status.message}</span>
			</form>
			<div className="my-4 separator"></div>
			<div className="flex">
				<p className="text-sm">
					Prestige Cars <br />
					44 West Street <br />
					CHIPPING NORTON
					<br /> OX7 5AA
					<br /> 07377 461846
					<br /> chippingnortoncabs@gmail.com
				</p>
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2459.7061799172498!2d-1.5485480842629078!3d51.93931268685209!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876d5798578a7df%3A0xed924e1e27e75e46!2sPRESTIGE%20CARS%20Chipping%20Norton!5e0!3m2!1sen!2suk!4v1660320159964!5m2!1sen!2suk"
					width="300"
					title="Google Maps"
					height="120"
					className="border-none rounded-xl ml-auto lg:relative absolute lg:visible invisible"
					allowFullScreen=""
					loading="lazy"
					referrerPolicy="no-referrer-when-downgrade"
				></iframe>
			</div>
		</div>
	);
}
